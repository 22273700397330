<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>员工考核</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
    <el-row class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>用户姓名：</label>
            <el-input placeholder="用户姓名"  v-model="receiveName" clearable class="width-220"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>所属部门：</label>
            <el-select  v-model="receiveOrgId" clearable placeholder="请选择">
              <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
              </el-option>
            </el-select>
          </el-row>
          <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
          <el-button type="primary"  @click="handleQuery(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
        </el-row>
      </el-row>
      <!-- 主体内容 -->
      <div class="table-box">
        <el-table
            ref="mainTable"
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :stripe="true"
            border fit
            >
          <el-table-column label="工号" width="60">
            <template slot-scope="scope">{{scope.$index + 1}}</template>
          </el-table-column>
          <el-table-column label="姓名" min-width="140">
            <template slot-scope="scope">{{scope.row.receiveName}}</template>
          </el-table-column>
          <el-table-column label="联系方式" min-width="140">receiveDuty
            <template slot-scope="scope">{{scope.row.receivePhone}}</template>
          </el-table-column>
          <el-table-column label="所属部门" min-width="90">
            <template slot-scope="scope">{{scope.row.receiveOrgName}}</template>
          </el-table-column>
          <el-table-column label="今日完成单量" min-width="200">
            <template slot-scope="scope">{{scope.row.todayCount}}</template>
          </el-table-column>
          <el-table-column label="全部单量" min-width="150">
            <template slot-scope="scope">{{scope.row.totalCount}}</template>
          </el-table-column>
          <el-table-column label="任务状态" min-width="150">
            <template slot-scope="scope">{{scope.row.taskStatus | filterStatus}}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="240" sortable fixed="right" v-show="this.hotelId !== 'undefined'">
            <template slot-scope="scope">
              <el-button type="text" style="color: #F56C6C;" @click="handleDetail('staff_assessment', scope.row)" >
                <el-link type="primary">明细</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
    </el-row>
  </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import { mapState } from 'vuex'
   import {hotelMan} from "@/api/interface/oms";
   export default {
      data(){
         return{
            tableData:[],     // 列表数据
            total:0,          // 总页数
            limit:0,          // 每页数
            page:1,           // 当前页
            loading: true,    // 动画加载效果
            options:[],       // 部门列表
            receiveName: '',  // 员工姓名
            receiveOrgId: '',  // 当前部门
            index:'',            //默认酒店
            hotelId:'',
         }
      },
      mounted(){
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getAssessments()
         this.department()
      },
      computed:{
         ...mapState(['hotelInfo']),
      },
      methods: {
         // 获取考核列表
         getAssessments(){
            const url = hotelMan.assessments
            console.log(this.hotelInfo)
            const param = {
               receiveOrgId: this.receiveOrgId,
               receiveName: this.receiveName,
               page: this.page,
               limit: this.limit,
               hotelId:this.hotelInfo.id
            }
            this.$axios.post(url, param).then(res => {
               this.loading = false
               this.total = res.total
               this.tableData = res.records
            })
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getAssessments()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getAssessments()
         },
         // 查询考核列表
         handleQuery(bool){
            if (bool) return this.getAssessments()
            this.receiveOrgId = ''
            this.receiveName = ''
         },
         // 跳转详情页
         handleDetail(action,row){
            sessionStorage.setItem('personalDatas',JSON.stringify((row)))
            this.$router.push({path: '/edit', query: {action,row}})

         },
         //所属部门下拉列表
         department(){
            const url = system.org
            const param = {
               companyId:this.tenantId,
               hotelId: this.hotelId,
               level:'2'
            }
            this.$axios.post(url, param, 'json').then(res => {
               this.options = res.children
            })
         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getAssessments()
                  this.department()
               }
            },
            deep: true
         }
      },
      filters:{
         filterStatus(val){
            if(val === '0'){
               return '空闲'
            }else{
               return '忙碌'
            }
         }
      }
   }
</script>

<style lang="scss">
.label{margin-left:5px;}
tbody .el-table__row td{text-align: center;}
.has-gutter tr th{text-align: center;}
.el-table th.is-leaf{text-align: center;}
.search{display: flex;}
.el-row div .label{font-size: 14px;}
</style>
